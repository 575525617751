<template>
  <div class="header-container">
    <div class="header-wrap">
      <div class="header-left flex-shrink-0" @click="handleGoHome">
        <!-- <img
          class="header-img"
          :src="pageInfo.centerLogoUrl"
          alt=""
          v-if="pageInfo.centerLogoUrlState == 1"
        /> -->
        <span v-if="pageInfo.centerWebsiteNameState == 1">{{
          pageInfo.centerWebsiteName
        }}</span>
      </div>
      <div class="header-menu flex-1 overflow-x-auto">
        <el-menu
          v-if="!isLoginPage"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#1bb2ad"
          text-color="#fff"
          active-text-color="#1bb2ad"
          @select="handleSelect"
        >
          <template v-if="userInfo.customerType != 8">
            <el-menu-item
              index="0"
              v-if="
                (userInfo.customerTypes.indexOf('1') != -1 ||
                  userInfo.customerTypes.indexOf('2') != -1) &&
                !isNonMedical
              "
              >{{ customerTyeCodeName }}管理</el-menu-item
            >
            <el-menu-item
              index="1"
              v-if="userInfo.customerTypes.indexOf('3') != -1"
              >首页</el-menu-item
            >
            <el-menu-item
              index="16"
              v-if="customerManageTypes?.indexOf('13') > -1"
              >检索</el-menu-item
            >
            <el-menu-item
              index="2"
              v-if="
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('1') > -1
              "
            >
              {{
                isNonMedical && !isYk && !isTunan
                  ? "中医类信息录入"
                  : "中医临床"
              }}
            </el-menu-item>
            <el-menu-item
              index="9"
              v-if="
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('2') > -1
              "
            >
              {{
                isNonMedical && !isYk && !isTunan
                  ? "中西医结合类信息录入"
                  : "中西医结合"
              }}
            </el-menu-item>
            <el-menu-item
              index="3"
              v-if="
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('3') > -1
              "
              >中医AI</el-menu-item
            >
            <el-menu-item
              index="10"
              v-if="
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('4') > -1
              "
            >
              {{
                isNonMedical && !isYk && !isTunan ? "慢性疾病录入" : "慢性疾病"
              }}
            </el-menu-item>
            <el-menu-item
              index="4"
              v-if="
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('5') > -1
              "
              >体质辨识</el-menu-item
            >
            <el-menu-item
              index="11"
              v-if="
                !isNonMedical &&
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('6') > -1
              "
              >中医康养</el-menu-item
            >
            <el-menu-item
              index="12"
              v-if="
                !isNonMedical &&
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('9') > -1
              "
              >中医针灸</el-menu-item
            >
            <el-menu-item
              index="14"
              v-if="
                !isNonMedical &&
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('11') > -1
              "
              >中医贴敷</el-menu-item
            >
            <el-menu-item
              index="15"
              v-if="
                !isNonMedical &&
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('12') > -1
              "
              >流感2025</el-menu-item
            >
            <el-menu-item
              index="13"
              v-if="
                !isNonMedical &&
                userInfo.customerTypes.indexOf('3') != -1 &&
                customerManageTypes?.indexOf('10') > -1
              "
              >知识库</el-menu-item
            >
            <!-- <el-menu-item index="5">新冠专题</el-menu-item> -->
            <!-- <el-menu-item index="6">操作演示</el-menu-item> -->
            <el-menu-item
              index="7"
              v-if="userInfo.userId == 1679091837127757832"
              >视频咨询</el-menu-item
            >
          </template>
          <template v-else>
            <el-menu-item index="8">专家咨询</el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="header-right flex-shrink-0">
        <div
          v-if="!isLoginPage"
          class="header-user"
          @click="toggleUserMenu"
          v-click-outside="outsideClick"
        >
          <img
            class="avatar-img"
            src="@/assets/images/user_nav_header_icon.png"
            alt=""
          />
          <div v-if="userInfo.userName" class="name">
            {{ userInfo.userName }}
          </div>
          <div v-else @click="goLogin" class="name">登录</div>
          <span class="arrow_down"></span>
          <div
            v-if="showUserMenu"
            :class="['user-menu', userInfo.customerType != 8 && 'posi']"
          >
            <div
              v-if="userInfo.customerType != 8"
              class="user-menu-item"
              @click="handleCommand('fixpassword')"
            >
              <img class="icon" src="@/assets/images/lock_icon.png" alt="" />
              修改密码
            </div>
            <div class="user-menu-item" @click="handleCommand('logout')">
              <img class="icon" src="@/assets/images/lagout_icon.png" alt="" />
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { logoutPost, logoutPost2 } from "@/service/user";

export default {
  name: "Header",
  data() {
    return {
      hidePathNames: ["login", "forgetPassword", "clinic", "demonstrate"],
      isShow: false,
      isLoginPage: false,
      activeIndex: "1",
      showUserMenu: false,
      curPageName: "",
      pagePath: [
        { path: "/clinicManager" },
        { path: "/home" },
        { path: "/clinical" },
        { path: "/channels" },
        { path: "/physique", query: { from: "physique" } },
        { path: "/covid19" },
        { path: "/demonstrate" },
        { path: "/videoConsultation" },
        { path: "/doctorVideo" },
        { path: "/clinical2" },
        { path: "/slow" },
        { path: "/healthy" },
        { path: "/acupuncture" },
        { path: "/knowledge" },
        { path: "/application" },
        { path: "/liugan" },
        { path: "/easy" },
      ],
    };
  },
  watch: {
    $route(to, from) {
      const name = to.name;
      this.filterCurIndex(name);
    },
  },
  computed: {
    ...mapState({
      customerTyeCodeName: (state) => state.user.customerTyeCodeName,
      userInfo: (state) => state.user.userInfo,
      isLogin: (state) => state.user.isLogin,
      isNonMedical: (state) => state.user.isNonMedical,
    }),
    ...mapGetters(["pageInfo"]),
    customerManageTypes() {
      if (!this.userInfo?.customerManageTypes) return [];
      if (typeof this.userInfo?.customerManageTypes == "object")
        return this.userInfo?.customerManageTypes;
      return this.userInfo?.customerManageTypes?.split(",");
    },
    isYk() {
      // 炎黄益康
      return (
        this.userInfo.customerAccountType == 5 ||
        this.userInfo.doctorAccountType == 5
      );
    },
    isTunan() {
      // 图南
      return (
        this.userInfo.customerAccountType == 6 ||
        this.userInfo.doctorAccountType == 6
      );
    },
  },
  created() {
    const { name } = this.$route;
    this.isLoginPage = this.hidePathNames.indexOf(name) > -1;
    this.filterCurIndex(name);
  },
  methods: {
    handleSelect(tab) {
      const pathArr = this.pagePath[tab].path.split("/");
      const name = pathArr[pathArr.length - 1];
      window.aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "_sjy.tab.clk",
          "CLK",
          {
            param1: name,
          },
        ],
      });
      this.$router.push(this.pagePath[tab]);
      // // if (tab == 0) this.$router.push({ path: "/clinicManager" });
      // // if (tab == 1) this.$router.push({ path: "/home" });
      // // if (tab == 2) this.$router.push({ path: "/clinical" });
      // // if (tab == 3) this.$router.push({ path: "/channels" });
      // if (tab == 4) this.$router.push({ path: "/physique", query: { from: 'physique' } });
      // if (tab == 5) this.$router.push({ path: "/covid19" });
      // if (tab == 6) this.$router.push({ path: "/demonstrate" });
      // if (tab == 7) this.$router.push({ path: "/videoConsultation" });
      // if (tab == 8) this.$router.push({ path: "/doctorVideo" });
      // if (tab == 9) this.$router.push({ path: "/clinical2" });
      // if (tab == 10) this.$router.push({ path: "/slow" });
      // if (tab == 11) this.$router.push({ path: "/healthy" });
      // if (tab == 12) this.$router.push({ path: "/acupuncture" });
      // if (tab == 13) this.$router.push({ path: "/knowledge" });
      // if (tab == 14) this.$router.push({ path: "/application" });
      // if (tab == 15) this.$router.push({ path: "/liugan" });
      // if (tab == 16) this.$router.push({ path: "/easy" });
    },
    async handleCommand(command) {
      if (command == "logout") {
        window.aplus_queue.push({
          action: "aplus.record",
          arguments: [
            '_sjy.header.logout.clk',
            "CLK",
            {
              param1: this.$route.name,
            },
          ],
        });
        try {
          const request =
            this.userInfo.customerType == 8 ? logoutPost2 : logoutPost;
          const { code, msg } = await request();
          if (code != 200) return this.$message.error(msg);
          this.$store.commit("user/setUserInfo", {});
          this.$store.commit("user/setCustomerTyeCodeName", 0);
          this.$store.commit("user/setIsLogin", false);
          this.$store.commit("user/setIsNonMedical", false);
          this.$store.commit("user/setAuthoritys", {});
          sessionStorage.removeItem("userInfo");
          let url = localStorage.getItem("url");
          window.location.href = url;
        } catch (error) {
          console.log(error);
        }
      } else {
        window.aplus_queue.push({
          action: "aplus.record",
          arguments: [
            '_sjy.header.editPassWord.clk',
            "CLK",
            {
              param1: this.$route.name,
            },
          ],
        });
        this.$router.push({
          name: "fixPassword",
        });
      }
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    goLogin() {
      this.$router.push({
        name: "login",
      });
    },
    filterCurIndex(name) {
      if (
        name == "clinicManager" ||
        name == "clinicEquipment" ||
        name == "clinicEquipmentRecord" ||
        name == "equipmentReport" ||
        name == "clinicDiagnose" ||
        name == "clinicPatient"
      )
        this.activeIndex = "0";
      if (name == "home") this.activeIndex = "1";
      if (name == "clinical") this.activeIndex = "2";
      if (name == "channels") this.activeIndex = "3";
      if (name == "physique") this.activeIndex = "4";
      if (name == "covid19") this.activeIndex = "5";
      if (name == "videoConsultation") this.activeIndex = "7";
      if (name == "doctorVideo" || name == "agora") this.activeIndex = "8";
      if (name == "clinical2") this.activeIndex = "9";
      if (name == "slow") this.activeIndex = "10";
      if (name == "healthy") this.activeIndex = "11";
      if (name == "acupuncture") this.activeIndex = "12";
      if (name == "knowledge" || name == "knowledgeDetail")
        this.activeIndex = "13";
      if (name == "application") this.activeIndex = "14";
      if (name == "liugan") this.activeIndex = "15";
      if (name == "easy") this.activeIndex = "16";
    },
    outsideClick() {
      this.showUserMenu = false;
    },
    handleGoHome() {
      this.$router.replace({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  min-width: 1200px;
  height: 62px;
  background-color: #19b2ad;

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 62px;
    margin: 0 auto;
  }

  .header-left {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    align-items: center;
    cursor: pointer;

    .header-img {
      flex-shrink: 0;
      width: 99px;
      height: 40px;
      margin-right: 16px;
    }
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  .header-menu {
    min-width: 528px;

    /deep/.el-menu {
      height: 62px;

      .el-menu-item {
        border-bottom: none;
        padding: 0 16px;
      }
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 6px;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }

    /deep/.el-menu-item.is-active {
      font-weight: 600;
      color: #fff !important;
      background-color: #13bfba !important;
    }

    /deep/.el-menu--horizontal > .el-menu-item {
      height: 62px;
      line-height: 62px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .header-user {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    .user-menu {
      position: absolute;
      left: 0;
      bottom: -54px;
      width: 130px;
      padding: 4px 0;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(38, 43, 48, 0.15);
      background-color: #fff;
      overflow: hidden;
      &.posi {
        bottom: -89px;
      }
      .user-menu-item {
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #383f47;
        padding-left: 12px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          background-color: #f2f5f7;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
    .avatar-img {
      width: 32px;
      height: 32px;
    }

    .name {
      max-width: 120px;
      font-size: 14px;
      color: #fff;
      margin: 0 4px 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .arrow_down {
      width: 16px;
      height: 16px;
      background: url("@/assets/images/arrow_down.png") no-repeat center;
      background-size: 16px;
    }
  }
}
</style>
