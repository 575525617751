var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-wrap"},[_c('div',{staticClass:"header-left flex-shrink-0",on:{"click":_vm.handleGoHome}},[(_vm.pageInfo.centerWebsiteNameState == 1)?_c('span',[_vm._v(_vm._s(_vm.pageInfo.centerWebsiteName))]):_vm._e()]),_c('div',{staticClass:"header-menu flex-1 overflow-x-auto"},[(!_vm.isLoginPage)?_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":"#1bb2ad","text-color":"#fff","active-text-color":"#1bb2ad"},on:{"select":_vm.handleSelect}},[(_vm.userInfo.customerType != 8)?[(
              (_vm.userInfo.customerTypes.indexOf('1') != -1 ||
                _vm.userInfo.customerTypes.indexOf('2') != -1) &&
              !_vm.isNonMedical
            )?_c('el-menu-item',{attrs:{"index":"0"}},[_vm._v(_vm._s(_vm.customerTyeCodeName)+"管理")]):_vm._e(),(_vm.userInfo.customerTypes.indexOf('3') != -1)?_c('el-menu-item',{attrs:{"index":"1"}},[_vm._v("首页")]):_vm._e(),(_vm.customerManageTypes?.indexOf('13') > -1)?_c('el-menu-item',{attrs:{"index":"16"}},[_vm._v("检索")]):_vm._e(),(
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('1') > -1
            )?_c('el-menu-item',{attrs:{"index":"2"}},[_vm._v(" "+_vm._s(_vm.isNonMedical && !_vm.isYk && !_vm.isTunan ? "中医类信息录入" : "中医临床")+" ")]):_vm._e(),(
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('2') > -1
            )?_c('el-menu-item',{attrs:{"index":"9"}},[_vm._v(" "+_vm._s(_vm.isNonMedical && !_vm.isYk && !_vm.isTunan ? "中西医结合类信息录入" : "中西医结合")+" ")]):_vm._e(),(
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('3') > -1
            )?_c('el-menu-item',{attrs:{"index":"3"}},[_vm._v("中医AI")]):_vm._e(),(
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('4') > -1
            )?_c('el-menu-item',{attrs:{"index":"10"}},[_vm._v(" "+_vm._s(_vm.isNonMedical && !_vm.isYk && !_vm.isTunan ? "慢性疾病录入" : "慢性疾病")+" ")]):_vm._e(),(
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('5') > -1
            )?_c('el-menu-item',{attrs:{"index":"4"}},[_vm._v("体质辨识")]):_vm._e(),(
              !_vm.isNonMedical &&
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('6') > -1
            )?_c('el-menu-item',{attrs:{"index":"11"}},[_vm._v("中医康养")]):_vm._e(),(
              !_vm.isNonMedical &&
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('9') > -1
            )?_c('el-menu-item',{attrs:{"index":"12"}},[_vm._v("中医针灸")]):_vm._e(),(
              !_vm.isNonMedical &&
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('11') > -1
            )?_c('el-menu-item',{attrs:{"index":"14"}},[_vm._v("中医贴敷")]):_vm._e(),(
              !_vm.isNonMedical &&
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('12') > -1
            )?_c('el-menu-item',{attrs:{"index":"15"}},[_vm._v("流感2025")]):_vm._e(),(
              !_vm.isNonMedical &&
              _vm.userInfo.customerTypes.indexOf('3') != -1 &&
              _vm.customerManageTypes?.indexOf('10') > -1
            )?_c('el-menu-item',{attrs:{"index":"13"}},[_vm._v("知识库")]):_vm._e(),(_vm.userInfo.userId == 1679091837127757832)?_c('el-menu-item',{attrs:{"index":"7"}},[_vm._v("视频咨询")]):_vm._e()]:[_c('el-menu-item',{attrs:{"index":"8"}},[_vm._v("专家咨询")])]],2):_vm._e()],1),_c('div',{staticClass:"header-right flex-shrink-0"},[(!_vm.isLoginPage)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideClick),expression:"outsideClick"}],staticClass:"header-user",on:{"click":_vm.toggleUserMenu}},[_c('img',{staticClass:"avatar-img",attrs:{"src":require("@/assets/images/user_nav_header_icon.png"),"alt":""}}),(_vm.userInfo.userName)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.userInfo.userName)+" ")]):_c('div',{staticClass:"name",on:{"click":_vm.goLogin}},[_vm._v("登录")]),_c('span',{staticClass:"arrow_down"}),(_vm.showUserMenu)?_c('div',{class:['user-menu', _vm.userInfo.customerType != 8 && 'posi']},[(_vm.userInfo.customerType != 8)?_c('div',{staticClass:"user-menu-item",on:{"click":function($event){return _vm.handleCommand('fixpassword')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/lock_icon.png"),"alt":""}}),_vm._v(" 修改密码 ")]):_vm._e(),_c('div',{staticClass:"user-menu-item",on:{"click":function($event){return _vm.handleCommand('logout')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/lagout_icon.png"),"alt":""}}),_vm._v(" 退出登录 ")])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }